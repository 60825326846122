import React, { Component } from 'react';
import Layout from '../components/structures/Layout/layout.js';
import Section from '../components/structures/Section/section';
import Wrapper from '../components/structures/Wrapper/wrapper';

class NotFoundPage extends Component {
  render() {
    const templateStyle = require(`../templates/${process.env.TEMPLATE}/structure/style-scheme-${process.env.STYLE_SCHEME}.json`);
    return (
      <Layout nav footer={templateStyle.footer.type}>
        <Section>
          <Wrapper>
            <div>
              <p className="e-text--error e-text--large">Page not found</p>
              <hr></hr>
              <p>We are sorry but the page your are looking for does not exist.</p>
            </div>
          </Wrapper>
        </Section>
      </Layout>
    );
  }
}

export default NotFoundPage;
